<template>
  <div class="single-bridge-common">
    <div class="tit-search flex flex-end">
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="pageGet(true)"
      ></c-search>
    </div>
    <div class="table-wrapper">
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-update" style="color:#F4BB4E" @click="getDetail(row)">修改</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="修改阈值管理"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="一级阈值上限" prop="firstWarnUpper">
                <el-input type="number" v-model="form.model.firstWarnUpper"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一级阈值下限" prop="firstWarnFloor">
                <el-input type="number" v-model="form.model.firstWarnFloor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="二级阈值上限" prop="secondWarnUpper">
                <el-input type="number" v-model="form.model.secondWarnUpper"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="二级阈值下限" prop="secondWarnFloor">
                <el-input type="number" v-model="form.model.secondWarnFloor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="三级阈值上限" prop="thirdWarnUpper">
                <el-input type="number" v-model="form.model.thirdWarnUpper"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="三级阈值下限" prop="thirdWarnFloor">
                <el-input type="number" v-model="form.model.thirdWarnFloor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="基准值" prop="baseValue">
                <el-input type="number" v-model="form.model.baseValue"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "/sensorDetail/threshold/listPage",
    pageDelPath: "",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    data() {
      let { searchItems,tableColumns } = Configs(this);
      return {
        searchItems,
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        form:{
          model:{},
          rules:{}
        },
        sensorList:[],
      };

    },
    created() {
      this.getSensor()
    },
    methods: {
      getSensor(){
        this.$http.get("/sensorAnalysis/sensorType/list").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
              this.searchItems[0].options = []
              for (let item of this.sensorList){
                this.searchItems[0].options.push({label: item.name, value: item.id})
              }
            }
          }
        })
      },
      getDetail(data){
        this.$http.get('/sensorDetail/threshold/detail', {params:{id:data.id}}).then(res => {
          if (res.success){
            this.form.model = res.data
            this.dialogVisible = true;
          }
        })
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post("/sensorDetail/threshold/update",this.form.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet()
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-update:hover{
    cursor: pointer;
  }
</style>