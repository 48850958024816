import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "传感器",
      span:24,
      type: "select",
      model: "sensorId",
      options: [],
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "传感器编号",
      prop: "name",
      align: "center",
      show: true
    },
    {
      label: "传感器名称",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "传感器位置名称",
      prop: "subName",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
